import React, { useState, useEffect, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import GeoSuggest from "../widgets/GeoSuggest";
import GetIP from "../widgets/GetIP";
import { useQuery } from '@apollo/client';
import { Animated } from "react-animated-css";
import { Scrollbars } from "react-custom-scrollbars-2";

//API
import { BUSINESS_TYPE } from "../../../gql/Query";

export default function StickySearch() {
    const { pathname } = useLocation();
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const history = useHistory();
    const maxLength = 20;
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [showDist, setShowDist] = useState(false);
    const [search, setSearch] = useState({});
    const [show, setShow] = useState(false);
    //Get local data
    const position = localStorage.getItem("position");
    const service_name = localStorage.getItem("service_name");
    // const select_loc = localStorage.getItem("select_loc");
    const dist = localStorage.getItem("dist");
    const wrapperRef = useRef(null);
    const [name, setName] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [distance, setDistance] = useState(20);
    const [gLocation, setGLocation] = useState("");
    const isIP = localStorage.getItem("loc");
    const location = localStorage.getItem("select_loc");
    const userLocation = GetIP();
    // console.log(userLocation)
    const storeService = []
    const { data: service_list } = useQuery(BUSINESS_TYPE);
    // get geo location's output
    const getGeoLocation = (value, lat, lng, code) => {
        // console.log("value, lat, lng, code", value, lat, lng, code)
        history.push(`/search?lat=${lat}&lng=${lng}&service=${name}&dist=${distance}&sort=""&gender=""`)
        if (lat && lng) {
            storeService.push(lat, lng, distance);
            //console.log(storeService);
            localStorage.setItem("position", JSON.stringify(storeService));
        }
        if (code === "101") {
            setGLocation(value);
        } else {
            if (userLocation !== "error") {
                if (userLocation?.city && userLocation?.country_name) {
                    setGLocation(`${userLocation.city}, ${userLocation.country_name}`)
                    localStorage.setItem("select_loc", `${userLocation?.city}, ${userLocation?.country_name}`);
                } else {
                    setGLocation("")
                }
            }
        }
        setVisible2(false)
        setTimeout(() => {
            setShow(false)
        }, 500)
    }
    useEffect(() => {
        if (!isIP) {
            if (userLocation?.latitude && userLocation?.longitude) {
                setLat(userLocation.latitude);
                setLng(userLocation.longitude);
            }
        }
        if (location) {
            setGLocation(location)
        }
        else {
            if (userLocation?.city && userLocation?.country_name) {
                setGLocation(`${userLocation.city}, ${userLocation.country_name}`)
            }
        }
        if (service_list) {
            setSearch(service_list.business_types);
            // console.log("service_list.service_categories", service_list.service_categories)
        }
        if (service_name) {
            setName(service_name)
        }
        if (dist) {
            setDistance(dist)
        }
    }, [userLocation, service_list, isIP, location, service_name, dist]);
    // console.log(gLocation)
    //Distance
    const distanceList = [1, 2, 3, 5, 10, 15, 20]
    const selectService = (e, name) => {
        e.preventDefault();
        localStorage.setItem("service_name", name);
        setName(name)
        setOpen1(false);
        if (!location && userLocation !== "error") {
            localStorage.setItem("select_loc", `${userLocation?.city}, ${userLocation?.country_name}`);
        }
        history.push(`/search?lat=${lat}&lng=${lng}&service=${name}&dist=${distance}&sort=""&gender=""`)
    }

    const distanceSearch = (d) => {
        console.log("distanceSearch", d)
        // setServiceQuery({ ...serviceQuery, distance: e.target.value })
        setDistance(d);
        localStorage.setItem("dist", d);
        setOpen2(false);
        setTimeout(() => {
            setShowDist(false);
        }, 500)
        history.push(`/search?lat=${lat}&lng=${lng}&service=${name}&dist=${d}&sort=""&gender=""`)
    }
    useEffect(() => {
        if (position) {
            const positionVal = [JSON.parse(position)]
            if (positionVal?.length > 0) {
                setLat(positionVal[0][0]);
                setLng(positionVal[0][1])
            }
        } else {
            if (userLocation?.latitude && userLocation?.longitude) {
                setLat(userLocation.latitude);
                setLng(userLocation.longitude);
            }
        }
        if (dist) {
            setDistance(dist)
        }
    }, [position, dist, userLocation.latitude, userLocation.longitude])

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen2(false);
                    setTimeout(() => {
                        setShowDist(false);
                    }, 500)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(wrapperRef);
    return (
        <>
            {
                pathname === "/search-form" ?
                    <div className="sticky-search-wrap">
                        <div className="sticky-search-inner">
                            <i className="fa fa-search" onClick={(e) => { selectService(e, name) }}></i>
                            <input
                                onFocus={() => {
                                    if (visible2) {
                                        setVisible2(false);
                                        setTimeout(() => {
                                            setShow(false)
                                        }, 500)
                                    }
                                    setOpen1(true);
                                    setVisible1(true);
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setVisible1(false)
                                    }, 300)
                                    setTimeout(() => {
                                        setOpen1(false)
                                    }, 500)
                                }}
                                aria-controls="sticky-search-collapse1"
                                type="search"
                                placeholder="Search for a service"
                                className="service-input"
                                value={name}
                                onChange={(e) => { setName(e.target.value); }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        selectService(e, name)
                                    }
                                }}
                            />
                            {open1 &&
                                <Animated animationIn="zoomIn" animationInDelay={100} isVisible={visible1}>
                                    <div id="sticky-search-collapse1">
                                        <Scrollbars>
                                            <div className="categories-wrapper">
                                                <span onClick={(e) => { selectService(e, "") }}>
                                                    All categories
                                                </span>
                                                {
                                                    search?.length > 0 &&
                                                    search.map((item) => (
                                                        <>
                                                            <span key={item.id} onClick={(e) => { selectService(e, item.name) }}>
                                                                {item.name}
                                                            </span>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                            {/* <div className="recent-search-wrapper">
                                                <h5>Recent searches</h5>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>All categories</span>
                                                </Link>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>Beauty Salon</span>
                                                </Link>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>Hair Salon</span>
                                                </Link>
                                            </div> */}
                                        </Scrollbars>
                                    </div>
                                </Animated>
                            }
                        </div>
                        <div className="d-flex justify-content-center mt-3 align-items-center">
                            <div className="location-inner">
                                {!show &&
                                    (gLocation?.length > maxLength ?
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 500, hide: 750 }}
                                            overlay={(props) => (
                                                <Tooltip id="button-tooltip" {...props}>
                                                    {gLocation}
                                                </Tooltip>
                                            )}
                                        >
                                            <h3 onClick={() => { setShow(!show); setVisible2(!visible2) }} className="mt-1">
                                                in <span>{gLocation ? `${gLocation.substring(0, 15)}...` : ""}</span>
                                            </h3>
                                        </OverlayTrigger> :
                                        <h3 onClick={() => { setShow(!show); setVisible2(!visible2) }} className="mt-1" >
                                            in <span>{gLocation ? gLocation : ""}</span>
                                        </h3>
                                    )
                                }
                                {show &&
                                    <Animated animationIn="zoomIn" animationOut="zoomOut" animationInDelay={100} animationOutDelay={200} isVisible={visible2}>
                                        <GeoSuggest onSelectLocation={getGeoLocation} />
                                    </Animated>
                                }
                            </div>
                            <div
                                className=''
                                ref={wrapperRef}
                            >
                                <div
                                    className="py-2 border-0 head-distance ms-3 px-3"
                                    onClick={() => {
                                        setOpen2(true);
                                        setTimeout(() => {
                                            setShowDist(true);
                                        }, 500)
                                    }}
                                >
                                    {distance}km
                                </div>
                                {showDist &&
                                    <Animated
                                        animationIn="zoomIn"
                                        animationOut="zoomOut"
                                        animationInDelay={100}
                                        animationOutDelay={200}
                                        isVisible={open2}
                                    >
                                        <div className='bg-white shadow-sm distance-list' style={{ marginLeft: "10px" }}>
                                            {
                                                distanceList?.map((item) => (
                                                    <div
                                                        key={item}
                                                        value={item}
                                                        onClick={() => { distanceSearch(item) }}
                                                    >
                                                        {item}km
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Animated>
                                }
                            </div>
                        </div>
                    </div> :
                    <div className="sticky-search-wrap">
                        <div className="sticky-search-inner">
                            <i className="fa fa-search" onClick={(e) => { selectService(e, name) }}></i>
                            <input
                                onFocus={() => {
                                    if (visible2) {
                                        setVisible2(false);
                                        setTimeout(() => {
                                            setShow(false)
                                        }, 500)
                                    }
                                    setOpen1(true);
                                    setVisible1(true);
                                }}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setVisible1(false)
                                    }, 300)
                                    setTimeout(() => {
                                        setOpen1(false)
                                    }, 500)
                                }}
                                aria-controls="sticky-search-collapse1"
                                type="search"
                                placeholder="Search for a service"
                                className="service-input"
                                value={name}
                                onChange={(e) => { setName(e.target.value); }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        selectService(e, name)
                                    }
                                }}
                            />
                            {open1 &&
                                <Animated animationIn="zoomIn" animationInDelay={100} isVisible={visible1}>
                                    <div id="sticky-search-collapse1">
                                        <Scrollbars>
                                            <div className="categories-wrapper pe-2" ref={wrapperRef}>
                                                {/* <Link to="/search" className="all-categories">
                                                    <i className="far fa-star"></i>
                                                    All categories
                                                </Link>
                                                <Link to="/search">
                                                    Beauty Salon
                                                </Link> */}
                                                <span onClick={(e) => { selectService(e, "") }}>
                                                    All categories
                                                </span>
                                                {
                                                    search?.length > 0 &&
                                                    search.map((item) => (
                                                        <>
                                                            <span key={item.name} onClick={(e) => { selectService(e, item.name) }}>
                                                                {item.name}
                                                            </span>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                            {/* <div className="recent-search-wrapper">
                                                <h5>Recent searches</h5>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>All categories</span>
                                                </Link>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>Beauty Salon</span>
                                                </Link>
                                                <Link to="/search" className="recent-search-item d-flex align-items-center">
                                                    <i className="fab fa-searchengin"></i>
                                                    <span>Hair Salon</span>
                                                </Link>
                                            </div> */}
                                        </Scrollbars>
                                    </div>
                                </Animated>
                            }
                        </div>
                        <div className="location-inner">
                            {!show &&
                                (gLocation !== undefined ?
                                    (gLocation?.length > maxLength ?
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 500, hide: 750 }}
                                            overlay={(props) => (
                                                <Tooltip id="button-tooltip" {...props}>
                                                    {gLocation}
                                                </Tooltip>
                                            )}
                                        >
                                            <h3 className='m-0' onClick={() => { setShow(!show); setVisible2(!visible2) }}>
                                                in <span>{gLocation ? `${gLocation.substring(0, 15)}...` : ""}</span>
                                            </h3>
                                        </OverlayTrigger> :
                                        <h3 onClick={() => { setShow(!show); setVisible2(!visible2) }}>
                                            in <span>{gLocation ? gLocation : ""}</span>
                                        </h3>) :
                                    <h3 className='m-0' onClick={() => { setShow(!show); setVisible2(!visible2) }}>
                                        <span>Enter location</span>
                                    </h3>
                                )
                            }
                            {show &&
                                <Animated animationIn="zoomIn" animationOut="zoomOut" animationInDelay={100} animationOutDelay={200} isVisible={visible2}>
                                    <GeoSuggest onSelectLocation={getGeoLocation} />
                                </Animated>
                            }
                        </div>
                        <div className='position-relative' ref={wrapperRef}>
                            <div
                                className="py-2 border-0 head-distance ms-3 px-3"
                                onClick={() => {
                                    setOpen2(true);
                                    setTimeout(() => {
                                        setShowDist(true);
                                    }, 500)
                                }}
                            >
                                {distance === "100" ?
                                    " National" :
                                    `${distance}km`
                                }
                            </div>
                            {showDist &&
                                <Animated
                                    animationIn="zoomIn"
                                    animationOut="zoomOut"
                                    animationInDelay={100}
                                    animationOutDelay={200}
                                    isVisible={open2}
                                >
                                    <div className='bg-white shadow-sm distance-list' style={{ marginLeft: "10px" }}>
                                        {
                                            distanceList?.map((item) => (
                                                <div
                                                    className={item === +distance ? 'selected-distance single-distance' : "single-distance"}
                                                    key={item}
                                                    value={item}
                                                    onClick={() => { distanceSearch(item) }}
                                                >
                                                    {item}km
                                                </div>
                                            ))
                                        }
                                        <div
                                            className={+distance === 100 ? 'selected-distance single-distance' : "single-distance"}
                                            onClick={() => { distanceSearch(100) }}
                                        >
                                            National
                                        </div>
                                    </div>
                                </Animated>
                            }
                        </div>
                    </div>
            }
        </>
    )
}
