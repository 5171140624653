import React, { useState } from 'react';
import mobilescreen from '../../../../../dist/images/Services/app_download.png';
import Apk1 from '../../../../../dist/images/banner-slider/1.png';
import Apk2 from '../../../../../dist/images/banner-slider/2.png';
import { Link, useHistory } from "react-router-dom";
import GetIP from "../../../widgets/GetIP";

const DownloadApp = ({ idName }) => {

    const history = useHistory();
    const [name, setName] = useState("");

    const userLocation = GetIP();
    const storeService = []

    const selectService = (name) => {
        // console.log("-------name-------", name)
        localStorage.setItem("service_name", name);
        let position = localStorage.getItem("position");
        let select_loc = localStorage.getItem("select_loc");
        if (position && select_loc) {
            let p = JSON.parse(position);
            localStorage.setItem("service_name", name);
            let lat = p[0]?.toString();
            let long = p[1]?.toString();
            let dist = localStorage.getItem("dist");
            history.push(`/search?lat=${lat}&lng=${long}&service=${name}&dist=${dist}&sort=""&gender=""`)
        } else {
            if (userLocation !== "error") {
                localStorage.setItem("select_loc", `${userLocation?.city}, ${userLocation?.country_name}`);
                storeService.push(userLocation.latitude, userLocation.longitude, 20);
                history.push(`/search?lat=${userLocation?.latitude}&lng=${userLocation?.longitude}&service=${name}&dist=${20}&sort=""&gender=""`)
            }
            else {
                navigator.geolocation.getCurrentPosition(function (position) {
                    // console.log("position", position)
                    localStorage.setItem("select_loc", "");
                    storeService.push(position.coords.latitude, position.coords.longitude, 20);
                    history.push(`/search?lat=${position.coords.latitude}&lng=${position.coords.longitude}&service=${name}&dist=${20}&sort=""&gender=""`)
                });
            }
            //console.log("storeService", storeService);
            localStorage.setItem("position", JSON.stringify(storeService));
        }
        setName(name)
    }

    return (
        <section id={idName} className='app_section'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 order-md-1 order-2">
                        <div className='app_download_img'>
                            <img src={mobilescreen} width={289} alt='img' />
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 order-1 d-flex align-items-center">
                        <div className=''>
                            <div className="">
                                <div className="banner-search-wrap">
                                    <div className="banner-search-input">
                                        <input type="text" name="search" id="headerSearch" onChange={(e) => { setName(e.target.value) }} placeholder="Find a business near you"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    selectService(name)
                                                }
                                            }}
                                            className="shadow"
                                        />
                                    </div>
                                    <div className="banner-search-icon" style={{ cursor: "pointer" }} onClick={(e) => { selectService(name) }} >
                                        <i className="fas fa-search"></i>
                                    </div>
                                </div>
                                <div className="download-app mt-3">
                                    <Link className="gplay" to={{ pathname: "https://play.google.com/store/apps/details?id=com.chuzeday.chuzeday" }} target="_blank"><img src={Apk1} alt="app-icon" /></Link>
                                    <Link className="appstore" to={{ pathname: "https://apps.apple.com/us/app/chuzeday/id1626262034" }} target="_blank"><img src={Apk2} alt="app-icon" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default DownloadApp
