import React, { useEffect, useState, useRef } from 'react';
import MetaGenerator from '../widgets/MetaGenerator';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import 'dotenv/config'
import GetIP from '../widgets/GetIP';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import Reaptcha from 'reaptcha';
import { useParams } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

//API
import { PROFILE_CONTACT } from '../../../gql/Query';
import { PRICING_PLAN } from '../../../gql/Mutation';


const GuestQuery = () => {
    const reCaptcha = useRef();
    const [code, setCode] = useState("IE");
    const [verify, setVerify] = useState(false);
    const [checked, setChecked] = useState(false);
    const [phone, setPhone] = useState("");
    const [loader, setLoader] = useState(false);
    const [pricingQuery, setPricingQuery] = useState({
        name: "",
        email: "",
        desc: "",
        queryType: "general"
    });


    //-----------loggedin user information------------------
    const { data, error } = useQuery(PROFILE_CONTACT);

    const { queryType } = useParams()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPricingQuery((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const userLocation = GetIP();
    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code);
            console.log("country code-----------", code);
        }
        if (queryType === "pricing" || queryType === "careers" || queryType === "business_support" || queryType === "guest_support") {
            setPricingQuery((preValue) => {
                return {
                    ...preValue,
                    queryType: queryType
                }
            })
        }
        if (data) {
            if (data?.profileInformation) {
                const { first_name, last_name, email, mobile } = data?.profileInformation
                setPricingQuery(preQuery => {
                    return { ...preQuery, name: `${first_name} ${last_name}`, email: email }
                });
                setPhone(mobile);
            }
        }
        if (error) {
            if (error.message !== "Unauthenticated.") {
                console.log(error.message)
            }
        }
    }, [userLocation, queryType, data, error])

    const [pricingPlan] = useMutation(PRICING_PLAN);

    const onSubmit = (e) => {
        e.preventDefault();
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pricingQuery.name === "") {
            toast.error('Please enter your name!');
        } else if (pricingQuery.email === "" || !(re.test(pricingQuery.email))) {
            toast.error('Please enter your email address!');
        } else if (phone === "") {
            toast.error('Please enter your mobile number!');
        } else if (!checked) {
            toast.error('Please check the notification checkbox!');
        } else {
            if (verify) {
                setLoader(true);
                pricingPlan({
                    variables: {
                        name: pricingQuery.name,
                        email: pricingQuery.email,
                        phone: phone,
                        description: pricingQuery.desc,
                        query_type: pricingQuery.queryType
                    }
                }).then(({ data }) => {
                    if (data) {
                        //console.log(data);
                        if (data.pricingPlan?.status === 1) {
                            toast.success(data.pricingPlan?.message);
                            setPricingQuery({ name: "", email: "", desc: "", queryType: queryType });
                            setPhone("");
                            setLoader(false);
                        } else {
                            toast.error(data.pricingPlan?.message);
                            setLoader(false);
                        }
                    }
                }).catch(e => {
                    console.log(e);
                    toast.error("Internal server error!");
                    setLoader(false);
                })
            } else {
                toast.error("Please verify captcha!");
                setLoader(false);
            }
        }
    }

    //----------------captcha functions-----------------
    const verifyCallback = () =>{
        setVerify(true)
    }
    const reVerifyCallback =()=>{
        setVerify(false)
    }
    return (
        <>
            <MetaGenerator
                title="Contact Us"
            />
            <div className="aboutus-section">
                <div className="aboutus row">
                    <div className="mailLoginWrap col-md-8 mx-auto">
                        <div className="LoginTop">
                            <span className="title">Contact Us</span>
                            <Form className="signUpForm" onSubmit={onSubmit} autocomplete="off">
                                <div className="formInput">
                                    <Row>
                                        <Form.Group as={Col} md={6} className="mb-3">
                                            <Form.Label>Name*</Form.Label>
                                            <Form.Control type="text" placeholder="Ex: John Doe" name="name" value={pricingQuery.name} onChange={(e) => { handleChange(e) }} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="mb-3">
                                            <Form.Label>Email address*</Form.Label>
                                            <Form.Control type="email" placeholder="Ex: example@mail.com" name="email" value={pricingQuery.email} onChange={(e) => { handleChange(e) }} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} className="mb-3">
                                            <Form.Label htmlFor="inlineFormInputGroup">
                                                Mobile Number*
                                            </Form.Label>
                                            <InputGroup className="Number d-block">
                                                <PhoneInput
                                                    international
                                                    defaultCountry={code}
                                                    placeholder="Enter phone number"
                                                    name="phone"
                                                    value={phone}
                                                    onChange={setPhone}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md={12} className="mb-3">
                                            <Form.Label htmlFor="inlineFormInputGroup">
                                                Query type
                                            </Form.Label>
                                            <Form.Select
                                                className='form-control'
                                                name="queryType"
                                                value={pricingQuery.queryType}
                                                onChange={(e) => { handleChange(e) }}
                                            >
                                                <option value="general">General</option>
                                                <option value="pricing">Pricing</option>
                                                <option value="guest_support">Guest Support</option>
                                                <option value="business_support">Business Support</option>
                                                <option value="careers">Careers at Chuzeday</option>
                                                <option value="technical_support">Technical Support</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} className="mb-3">
                                            <Form.Label>Query</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Write your query here..."
                                                name="desc" value={pricingQuery?.desc}
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} className="mb-3 ps-3">
                                            <Form.Check
                                                inline
                                                label="Do you want to be notified of Chuzeday developements?"
                                                type="checkbox"
                                                onChange={() => { setChecked(!checked) }}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>
                                <div className='ps-1'>
                                    <Reaptcha
                                        ref={reCaptcha}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        render="explicit"
                                        onVerify={verifyCallback}
                                        onExpire={reVerifyCallback}
                                    />
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <button className="primaryBtn btn  py-2 px-5 d-flex" disabled={loader}>
                                        Submit
                                        {
                                            loader &&
                                            <div className="ms-2"><Spinner animation="border" /></div>
                                        }
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default GuestQuery
