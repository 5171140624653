import React, { createContext, useState, useEffect, useContext } from 'react';
import Basket from './Basket';
import { Modal, Spinner } from 'react-bootstrap';
import Product from "./Product";
import Vouchers from "./Vouchers";
import { toast } from 'react-toastify';
import arrow from '../../../../../dist/images/Login/colourLeft.png';
import { useMutation, useQuery } from '@apollo/client';
import BookingLogin from "./BookingLogin";
import SelectedService from "./SelectedService";
import DateTimeModal from "./DateTimeModal";
import PWays from "./PWays";
import { ContSerVcr } from "../../SingleShop";
import VettingCall from "./VettingCall"
// import BackTop from '../../../../../dist/images/singleShop/services/toparrow.png';
import { Scrollbars } from "react-custom-scrollbars-2";
import GetCurrency from "../../../widgets/GetCurrency";
import moment from "moment";

//API
import { MY_APPOINTMENTS, GUEST_STATUS, TIME_SLOT } from "../../../../../gql/Query";
import { BOOK_APPOINTMENT, GUEST_PAYMENT_LOG, REVOLUT_PUBLIC_ID } from "../../../../../gql/Mutation";

export const cartList = createContext();

const ShopService = () => {
    const { serviceList, singleShop, refetchShop } = useContext(ContSerVcr);
    // console.log("serviceList, singleShop", serviceList, singleShop)

    // const myRef = useRef(null);
    // const executeScroll = () => myRef.current.scrollIntoView();

    const [isService, setIsService] = useState(false);
    const [onlyVoucher, setOnlyVoucher] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);
    const [loadPay, setLoadPay] = useState(false);
    const [closeDays, setCloseDays] = useState({})
    const [name, setName] = useState("");
    const [addBack, setAddBack] = useState(0);
    const [show, setShow] = useState(false);
    const [login, setLogin] = useState(false);
    const [summery, setSummary] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payable, setPayable] = useState(0);
    const [paymentInfo, setPaymentInfo] = useState("");
    const [country, setCountry] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [tPriceString, setTPriceString] = useState("");
    const [bName, setBName] = useState("");
    const [videoVetting, setVideoVetting] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [voucherList, setVoucherList] = useState({});
    const [closeDate, setCloseDate] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalSPrice, setTotalSPrice] = useState(0);
    const [totalVPrice, setTotalVPrice] = useState(0);
    const [bID, setBID] = useState();
    const [upfront, setUpfront] = useState(0);
    const [currency, setcurrency] = useState("");
    const [guestStatus, setGuestStatus] = useState("");
    const [blockStatus, setBlockStatus] = useState(false);
    const [video_datetime, setVideo_datetime] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [blockedTime, setBlockedTime] = useState({});
    const [isVoucher, setIsVoucher] = useState([]);
    const [person, setPerson] = useState("");
    const [redeemMsg, setRedeemMsg] = useState("");
    const [applyRedeem, setApplyRedeem] = useState(0);
    //---------------------log data------------------
    const [system_log] = useMutation(GUEST_PAYMENT_LOG)
    //---------------------Guest status------------------------
    const { data: gStatus, refetch: refetchStatus } = useQuery(GUEST_STATUS, {
        variables: {
            business_id: parseInt(singleShop?.single_business?.business?.id)
        }
    });

    //-----------------Vetting call---------------
    const getVetDateTime = (t, d) => {
        setVideo_datetime(`${d} ${t}`);
        if (d === date) {
            if (moment(time, 'h:mm a').isSameOrBefore(moment(t, 'h:mm a'))) {
                toast.error("You must have to select consultation call time before the time you have selected for the services!");
            } else {
                setConfirmBtn(false);
            }
        } else {
            if (moment(date).isBefore(d)) {
                toast.error("You must have to select consultation call time before the time you have selected for the services!");
            } else {
                setConfirmBtn(false);
            }
        }
    }

    //---------------------------select time--------------------------
    const { error: slotError, refetch: slotRefetch } = useQuery(TIME_SLOT, {
        variables: {
            business_id: parseInt(bID),
            date: date
        }
    });

    //-----------------------get services and vouchers from Context API------------------
    useEffect(() => {
        if (singleShop) {
            if (singleShop?.single_business) {
                if (singleShop?.single_business?.business) {
                    setBID(singleShop.single_business.business.id);
                    setCountry(singleShop.single_business.business.country);
                    setUpfront(singleShop.single_business.business.upfront_amount);
                    setBName(singleShop.single_business.business.name);
                    setVideoVetting(singleShop.single_business.business?.video_vetting)
                }
                setVoucherList(singleShop?.single_business?.business_vouchers);
                setCloseDays(singleShop?.single_business?.close_weekday);
                setCloseDate(singleShop?.single_business?.close_date);
                setBlockedTime(singleShop?.single_business?.block_date);
            }
            if (singleShop?.me) {
                //console.log("singleShop?.me", singleShop?.me);
                setName(`${singleShop.me.first_name} ${singleShop.me.last_name}`);
            }
        }
        if (gStatus) {
            setGuestStatus(gStatus.guest_status?.status);
            setBlockStatus(gStatus.guest_status?.block)
            //console.log("gStatus-------------", gStatus)
        }
        if (slotError) {
            console.log("slotError", slotError)
        }
    }, [serviceList, singleShop, gStatus, slotError]);
    //----------get currency----------
    const c = GetCurrency(country)
    useEffect(() => {
        if (country && c) {
            setcurrency(c);
        }
    }, [country, c]);

    const [appointment_booking] = useMutation(BOOK_APPOINTMENT, {
        refetchQueries: [{
            query: MY_APPOINTMENTS,
            variables: {
                date_range: "",
                keyword: "",
                type: "upcoming",
                count: 10,
                page: 1
            }
        }],
        awaitRefetchQueries: true,
    });

    //---------------------Revolut public ID-------------------
    const [revolutPaymentPublicId] = useMutation(REVOLUT_PUBLIC_ID);

    //---------------select any of the payment ways-----------
    const onPaySelect = (type, withVoucher) => {
        setPaymentType(type);
        //console.log("type", type);
        if (withVoucher > 0) {
            if (type === "stripe" || type === "revolut") {
                setConfirmBtn(false);
                let infoData = JSON.parse(paymentInfo)
                if (type === "stripe") {
                    infoData = { ...infoData, stripe: applyRedeem, revolut: "" };
                    setPaymentInfo(JSON.stringify(infoData));
                }
                if (type === "revolut") {
                    infoData = { ...infoData, revolut: applyRedeem, stripe: "" };
                    setPaymentInfo(JSON.stringify(infoData));
                }
            }
        } else {
            if (type === "stripe") {
                setConfirmBtn(false);
                if (isService) {
                    setPaymentInfo(JSON.stringify({ voucher: {}, stripe: (voucherAmount + (serviceAmount * upfront / 100)).toFixed(2), revolut: "" }));
                } else {
                    setPaymentInfo(JSON.stringify({ voucher: {}, stripe: totalVPrice, revolut: "" }));
                }
            }
            if (type === "revolut") {
                setConfirmBtn(false);
                if (isService) {
                    setPaymentInfo(JSON.stringify({ voucher: {}, stripe: "", revolut: (voucherAmount + (serviceAmount * upfront / 100)).toFixed(2) }));
                } else {
                    setPaymentInfo(JSON.stringify({ voucher: {}, stripe: "", revolut: totalVPrice }));
                }
            }
        }
    }

    //------------payment info for voucher redeem-----------------
    const redeem = (code, v, s) => {
        //console.log("code, v, s", code, v, s)
        if (v > 0) {
            if (s === 0) {
                setPaymentInfo(JSON.stringify({
                    voucher: { code: code, payment: v }, stripe: "", revolut: ""
                }));
                setConfirmBtn(false);
                setRedeemMsg("");
            } else {
                setRedeemMsg(`You can use ${currency}${v} from the voucher. You have to pay rest of the amount ${currency}${s.toFixed(2)} to confirm booking. Please select your payment method!`);
                setApplyRedeem(s);
                setPaymentInfo(JSON.stringify({
                    voucher: { code: code, payment: v }, stripe: "", revolut: ""
                }));
            }
            setTPriceString(JSON.stringify({ service: serviceAmount, voucher: totalVPrice }))
        } else {
            setPaymentInfo(JSON.stringify({ voucher: {}, stripe: (voucherAmount + (serviceAmount * upfront / 100)).toFixed(2), revolut: "" }));
        }
    }

    //-------------------selected cartItems data--------------------
    var price = 0;
    var sp_price = 0;
    var service_price = 0;
    var serviceAmount = 0;
    var voucherAmount = 0;
    var updatedTotalAmount = 0;
    // var subPrice = 0;
    if (cartItems) {
        cartItems.map((x) => {
            if (!x.retail) {
                // console.log("sp_price, x.special_price", sp_price, x.special_price)

                //console.log("sp_price", sp_price);
                if (x.special_price === 0) {
                    price += x.price
                    sp_price = sp_price + x.price;
                    service_price = service_price + x.price;
                    serviceAmount += x.price;
                } else {
                    price += x.price;
                    sp_price = sp_price + x.special_price;
                    service_price = service_price + x.special_price;
                    serviceAmount += x.special_price;
                }
            } else {
                // console.log("sp_price, x.retail", sp_price, x.retail)

                updatedTotalAmount = x.retail * x.qty;
                sp_price += updatedTotalAmount;
                voucherAmount += updatedTotalAmount;

                // console.log(x)
                // sp_price = sp_price + x.retail
            }
        })
    }
    // console.log("sp_price", sp_price)
    useEffect(() => {
        if (cartItems) {
            var pybl
            if (upfront > 0) {
                pybl = (serviceAmount * upfront / 100).toFixed(2)
                setPayable(parseFloat(pybl))
                setPaymentInfo(JSON.stringify({ voucher: {}, stripe: pybl, revolut: pybl }))
            } else {
                setPaymentInfo(JSON.stringify({ voucher: {}, stripe: "", revolut: "" }))
            }
            // console.log("price - serviceAmount", price, serviceAmount)
            if (price > serviceAmount) {
                setDiscount(price - serviceAmount);
            } else {
                setDiscount(0);
            }
            // setSubTotal(sp_price);
            setTotalPrice(sp_price);
            setTotalSPrice(serviceAmount)
            setTotalVPrice(voucherAmount)
            setTPriceString(JSON.stringify({ service: serviceAmount, voucher: voucherAmount }));
            if (cartItems) {
                for (const item of cartItems) {
                    if (item.__typename === "ServicePricing") {
                        setIsService(true);
                        break;
                    } else {
                        setIsService(false);
                    }
                }
            }
        }
    }, [cartItems, price, sp_price, serviceAmount, voucherAmount, upfront])

    const guest_token = localStorage.getItem('guest_token');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //---------------------------------Add to cart------------------------------
    const onAdd = (product, person) => {
        if (product?.__typename === "Voucher") {
            //console.log("product", product)
            setCartItems([...cartItems, { ...product, qty: 1, person: person }]);
        } else {
            setCartItems([...cartItems, { ...product, qty: 1 }]);
        }
    };

    //------------------------Increment------------------------------
    const inc = (id, name) => {
        // console.log("id", id)
        const updatedCart = cartItems.map((curElem) => {
            if (curElem.id === id && curElem.name === name) {
                return { ...curElem, qty: curElem.qty + 1 };
            }
            return curElem;
        });
        // console.log("updatedCart", updatedCart)
        setCartItems(updatedCart);
    };

    //-------------------------------Decrement------------------------------------
    const dec = (id, name) => {
        const updatedCart = cartItems
            .map((curElem) => {
                if (curElem.id === id && curElem.name === name) {
                    return { ...curElem, qty: curElem.qty - 1 };
                }
                return curElem;
            })
            .filter((curElem) => curElem.qty !== 0);
        setCartItems(updatedCart);
    };

    //--------------------Remove from cart---------------------
    const onRemove = (product) => {
        setAddBack(product.id)
        setCartItems(cartItems.filter((x) => x.id !== product.id));
        toast.warning("Removed from the cart!");
    };

    //--------------------- cancellation list---------------------
    const canList = () => {
        setShow(false);
        setCartItems([]);
    }

    // console.log("date, time", date, time);
    const getAuth = (val) => {
        refetchStatus();
        setSummary(val)
    }
    const checkAuth = (time, date) => {
        setTime(time);
        setDate(date);
        if (!guest_token) {
            toast.info("Please login first!");
            setLogin(true)
        } else {
            setSummary(true);
        }
    }
    const goBack = (val) => {
        setLogin(val)
    }
    const formatServices = () => {
        const servicePriceIDList = [];
        const voucherPriceIDList = [];
        if (cartItems) {
            cartItems.map((x) => {
                if (x.__typename === "ServicePricing") {
                    servicePriceIDList.push(x.id)
                } else {
                    voucherPriceIDList.push({ id: x.id, qty: x.qty, name: person })
                }
            })
        }
        setIsVoucher(voucherPriceIDList);
        const totalIDList = {
            service: servicePriceIDList,
            voucher: voucherPriceIDList
        }
        return JSON.stringify(totalIDList);
    }
    const confirmPurchase = () => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            //console.log("paymentInfo", paymentInfo);
            if (!blockStatus) {
                //console.log("paymentInfo--------------", paymentInfo)

                let services = formatServices()
                // console.log("date, time, bID, services, tPriceString, paymentInfo, payable,discount,video_datetime ---------------",
                //     date, time, bID, services, tPriceString, paymentInfo, payable, discount, video_datetime)
                //console.log('AMOUNT', amount);

                setLoadPay(true);
                setConfirmBtn(true);

                appointment_booking({
                    variables: {
                        date: date,
                        time: time,
                        business_id: bID,
                        services: services,
                        total_amount: tPriceString,
                        payment_info: paymentInfo,
                        upfront_amount: payable,
                        discount: discount,
                        video_datetime: video_datetime
                    }
                }).then(({ data }) => {
                    if (data) {
                        // console.log("booking response", data)
                        if (data.appointment_booking.status === 1) {
                            //console.log(data.appointment_booking.message, data);
                            if (date !== "") {
                                slotRefetch();
                            }
                            setShow(false);
                            setSummary(false);
                            setTimeout(() => {
                                setTime("");
                                setDate("");
                            }, 1000);
                            setCartItems([]);
                            setLoadPay(false);
                            setApplyRedeem(0);
                            setRedeemMsg("");
                            setPaymentType("")
                            if (data.appointment_booking.payment_url) {
                                window.open(data.appointment_booking.payment_url, "__blank");
                                refetchShop()
                            } else {
                                if (paymentType === "revolut") {
                                    const sale_id = data.appointment_booking.sale_id;
                                    const amount = data.appointment_booking?.payment_amount
                                    revolutPaymentPublicId({
                                        variables: {
                                            amount: amount,
                                            currency: "EUR"
                                        }
                                    }).then(({ data }) => {
                                        //console.log("public key", data?.revolutPaymentPublicId?.id);
                                        if (data?.revolutPaymentPublicId) {
                                            window.open(`/revolut/${data?.revolutPaymentPublicId?.public_id}/${amount}/${sale_id}/${data?.revolutPaymentPublicId?.id}`, "_blank");
                                            refetchShop();
                                        }
                                    }).catch(e => {
                                        system_log({
                                            variables: {
                                                type: "payment_confirmation",
                                                sale_id: data.appointment_booking.sale_id,
                                                message: data.appointment_booking.message,
                                            }
                                        })
                                        console.log("revolut error", e)
                                    })
                                }
                                if (paymentType === "") {
                                    toast.success("Booking confirmed!");
                                    refetchShop();
                                }
                            }
                        } else {
                            system_log({variables:{
                                type:  "payment_confirmation",
                                sale_id: data.appointment_booking.sale_id,
                                message: data.appointment_booking.message,
                            }})
                            toast.error(data.appointment_booking.message);
                            //console.log(data.appointment_booking.message, data)
                        }
                        setLoadPay(false)
                        setConfirmBtn(false);
                    }
                }).catch(e => {
                    console.log(e);
                    system_log({
                        variables: {
                            type: "payment_confirmation",
                            sale_id: undefined,
                            message: JSON.stringify(e),
                        }
                    })
                    setLoadPay(false)
                    setConfirmBtn(false);
                    toast.error("Internal server error!");
                })
            } else {
                toast.error("You have been blocked by the business!")
            }
        } else {
            window.location.reload();
        }
    }
    return (
        <cartList.Provider value={cartItems}>
            <div id="ShopServicesRoot">
                {
                    serviceList && serviceList?.length > 0 &&
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 pe-0">
                                <div>
                                    <h2 className="sectionHeading">Book A Service</h2>
                                    <div className="servicesItemWrap">
                                        {
                                            serviceList?.map((item) => (
                                                <Product
                                                    key={item.id}
                                                    content={item}
                                                    onAdd={onAdd}
                                                    addBack={addBack}
                                                    currency={currency}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* --------------------Vouchers------------------------ */}
                                {
                                    voucherList?.length > 0 &&
                                    <div className='mt-5'>
                                        <h2 className="sectionHeading">Vouchers</h2>
                                        <div className='row servicesItemWrap'>
                                            {
                                                voucherList?.map((item) => (
                                                    <Vouchers
                                                        key={item.id}
                                                        content={item}
                                                        onAdd={onAdd}
                                                        addBack={addBack}
                                                        profileName={name}
                                                        currency={currency}
                                                        person={person}
                                                        setPerson={setPerson}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-5">
                                <div className="servicesCartWrap">
                                    <section id="cart">
                                        <div className="cartInner">
                                            <h3>{bName ? bName : ""}</h3>
                                            <h2>My Cart</h2>
                                            {cartItems?.length === 0 ?
                                                <div className='Category'> <div className="default-text">Cart is empty</div></div>
                                                :
                                                cartItems?.length > 3 ?
                                                    <div className='cartItems'>
                                                        <Scrollbars>
                                                            <>
                                                                {/* <div ref={myRef}></div> */}
                                                                {
                                                                    cartItems?.map((item) => (
                                                                        <Basket
                                                                            key={item.id}
                                                                            content={item}
                                                                            onAdd={onAdd}
                                                                            onRemove={onRemove}
                                                                            inc={inc}
                                                                            dec={dec}
                                                                            currency={currency}
                                                                        />
                                                                    ))
                                                                }
                                                            </>
                                                        </Scrollbars>
                                                    </div> :
                                                    cartItems?.map((item) => (
                                                        <Basket
                                                            key={item.id}
                                                            content={item}
                                                            onAdd={onAdd}
                                                            onRemove={onRemove}
                                                            inc={inc}
                                                            dec={dec}
                                                            currency={currency}
                                                        />
                                                    ))
                                            }
                                            {
                                                discount > 0 &&
                                                <div className="cartVat">
                                                    <span className="vatinfo">Discount</span>
                                                    <div className="vatamonunt">
                                                        <span className='h3 mt-2 ms-2'>{currency}</span>
                                                        <span>{discount}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div>
                                                {cartItems.length !== 0 && (
                                                    <>
                                                        {
                                                            upfront > 0 && isService &&
                                                            <div className="cartVat">
                                                                <span className="vatinfo">Deposit amount</span>
                                                                <div className="vatamonunt">
                                                                    <span>{upfront}%</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="totalCost">
                                                            <span>Total Cost</span>
                                                            <div className="vatamonunt">
                                                                <span className='h3 mt-2 ms-2'>{currency}</span>
                                                                <span>{totalPrice}</span>
                                                            </div>
                                                        </div>
                                                        <h6>*vat included</h6>
                                                    </>
                                                )}
                                            </div>
                                            {/* {
                                                cartItems?.length > 3 &&
                                                <div className="arrowTop" onClick={executeScroll}>
                                                    <img src={BackTop} alt="icon" />
                                                </div>
                                            } */}
                                            <div className="cartCheckBtn venuItem mt-3 text-center">
                                                {
                                                    isService ?
                                                        <button onClick={() => { handleShow(); setOnlyVoucher(false) }} className="primaryBtn btn mx-auto" disabled={cartItems?.length > 0 ? false : true}>
                                                            <span>Next <i className="arrow fa fa-angle-right"></i></span>
                                                        </button> :
                                                        <button onClick={() => { handleShow(); setOnlyVoucher(true) }} className="primaryBtn btn mx-auto" disabled={cartItems?.length > 0 ? false : true}>
                                                            <span>Next <i className="arrow fa fa-angle-right"></i></span>
                                                        </button>
                                                }
                                                <Modal size="md" aria-labelledby="contained-modal-title-vcenter"
                                                    centered show={show} onHide={handleClose}>
                                                    {!summery ?
                                                        (!login ?
                                                            <>
                                                                {!isService && onlyVoucher ?
                                                                    <>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title className='BackHome'>
                                                                                <span onClick={() => { setSummary(false) }} className="BackHome">
                                                                                    <img src={arrow} alt="icon" />
                                                                                </span>
                                                                                <span>Confirm Appointment</span>
                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            {
                                                                                guestStatus &&
                                                                                <>
                                                                                    <SelectedService
                                                                                        date={""}
                                                                                        time={""}
                                                                                        cartItems={cartItems}
                                                                                        voucherAmount={totalVPrice}
                                                                                        serviceAmount={totalSPrice}
                                                                                        upfront={upfront}
                                                                                        currency={currency}
                                                                                        guestStatus={guestStatus}
                                                                                        vettingCall={videoVetting}
                                                                                    />
                                                                                    {blockStatus ?
                                                                                        <div>
                                                                                            <p className='text-danger text-center mt-3'>
                                                                                                You have been blocked by this business!
                                                                                            </p>
                                                                                        </div> :
                                                                                        <PWays
                                                                                            serviceAmount={totalSPrice}
                                                                                            upfront={upfront}
                                                                                            redeem={redeem}
                                                                                            onPaySelect={onPaySelect}
                                                                                            currency={currency}
                                                                                            redeemMsg={redeemMsg}
                                                                                            applyRedeem={applyRedeem}
                                                                                            totalVPrice={totalVPrice}
                                                                                            paymentType={paymentType}
                                                                                        />
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </Modal.Body>
                                                                        <div className="select-time-btn-wrap pb-2">
                                                                            <button disabled={upfront > 0 ? confirmBtn : false} onClick={() => { confirmPurchase() }} className="btn primaryBtn">
                                                                                {loadPay ?
                                                                                    <>
                                                                                        <span className='me-2'>
                                                                                            PLease wait...
                                                                                        </span>
                                                                                        <span>
                                                                                            <Spinner animation="border" variant="white" />
                                                                                        </span>
                                                                                    </> :
                                                                                    <span>
                                                                                        Confirm  <i className="arrow fa fa-angle-right" />
                                                                                    </span>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </> :
                                                                    <DateTimeModal
                                                                        closeDays={closeDays}
                                                                        bID={bID}
                                                                        checkAuth={checkAuth}
                                                                        closeDate={closeDate}
                                                                        canList={canList}
                                                                        vetting={false}
                                                                        formatServices={formatServices}
                                                                        blockedTime={blockedTime}
                                                                        notification={false}
                                                                    />
                                                                }
                                                            </> :
                                                            (date && time && cartItems && totalPrice) &&
                                                            <>
                                                                {/* <BookingLogin getAuth={getAuth} goBack={goBack} date={date} time={time} cartItems={cartItems} totalPrice={totalPrice} upfront={upfront} /> */}
                                                                <BookingLogin
                                                                    getAuth={getAuth}
                                                                    goBack={goBack}
                                                                    date={date}
                                                                    time={time}
                                                                    cartItems={cartItems}
                                                                    upfront={upfront}
                                                                    totalVPrice={totalVPrice}
                                                                    totalSPrice={totalSPrice}
                                                                    currency={currency}
                                                                    guestStatus={guestStatus}
                                                                    videoVetting={videoVetting}
                                                                    setName={setName}
                                                                />
                                                            </>
                                                        ) :
                                                        <>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title className='BackHome'>
                                                                    <span onClick={() => { setSummary(false) }} className="BackHome">
                                                                        <img src={arrow} alt="icon" />
                                                                    </span><span>Confirm Appointment</span>
                                                                </Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <SelectedService
                                                                    date={date}
                                                                    time={time}
                                                                    cartItems={cartItems}
                                                                    voucherAmount={totalVPrice}
                                                                    serviceAmount={totalSPrice}
                                                                    upfront={upfront}
                                                                    currency={currency}
                                                                    guestStatus={guestStatus}
                                                                    vettingCall={videoVetting}
                                                                />
                                                                {
                                                                    blockStatus ?
                                                                        <div>
                                                                            <p className='text-danger text-center mt-3'>You have been blocked by this business!</p>
                                                                        </div> :
                                                                        ((guestStatus === "New" && singleShop?.single_business?.business?.video_vetting) && name ?
                                                                            <VettingCall
                                                                                closeDays={closeDays}
                                                                                bID={bID}
                                                                                checkAuth={checkAuth}
                                                                                closeDate={closeDate}
                                                                                blockedTime={blockedTime}
                                                                                canList={canList}
                                                                                getVetDateTime={getVetDateTime}
                                                                                video_datetime={video_datetime}
                                                                                name={name}
                                                                                bName={bName}
                                                                            />
                                                                            :
                                                                            (isVoucher?.length > 0 || upfront > 0) &&
                                                                            <PWays
                                                                                serviceAmount={totalSPrice}
                                                                                upfront={upfront}
                                                                                redeem={redeem}
                                                                                onPaySelect={onPaySelect}
                                                                                currency={currency}
                                                                                redeemMsg={redeemMsg}
                                                                                applyRedeem={applyRedeem}
                                                                                totalVPrice={totalVPrice}
                                                                                paymentType={paymentType}
                                                                            />)
                                                                }
                                                            </Modal.Body>
                                                            <div className="select-time-btn-wrap pb-2">
                                                                <button disabled={(isVoucher?.length > 0 || upfront > 0) ? confirmBtn : false} onClick={() => { confirmPurchase() }} className="btn primaryBtn">
                                                                    {loadPay ?
                                                                        <>
                                                                            <span className='me-2'>
                                                                                Please wait...
                                                                            </span>
                                                                            <span>
                                                                                <Spinner animation="border" variant="white" />
                                                                            </span>
                                                                        </> :
                                                                        <span>
                                                                            Confirm  <i className="arrow fa fa-angle-right" />
                                                                        </span>
                                                                    }
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </Modal>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </cartList.Provider>
    );
}

export default ShopService;