import React from 'react'
import { revoutAffiliateURl, toAbsoluteUrl } from '../../../baseUrl';
import revloutBanner from '../../../../dist/images/revolut/revolut-banner-01.jpg';
// import revloutHomeBanner from "../../../../dist/images/revolut/revolut-banner-home.jpg";
import revloutHomeBanner from "../../../../dist/images/revolut/revolut-banner-home.svg";
import revloutSquireBanner from "../../../../dist/images/revolut/revolut-banner.jpg";
import revloutSvgSquireBanner from "../../../../dist/images/revolut/revolut-banner.svg";

const RevolutAffiliateBanner = ({layout}) => {
  return (
    <section className="">
      {layout === "homePage" && (
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <a
                className="bg-transparent"
                href={revoutAffiliateURl}
                target="_blank"
              >
                <img
                  src={revloutHomeBanner}
                  // width={289}
                  alt="img"
                  className="w-100 mb-3 rounded-3"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      {layout === "businessProfile" && (
        <div className="action-bottom text-center mx-auto mt-5">
          <a href={revoutAffiliateURl} target="_blank">
            <img src={revloutBanner} alt="img" className="w-100 rounded-3" />
          </a>
        </div>
      )}
      {layout === "GuestProfile" && (
        <div className="action-bottom text-center mx-auto mt-5">
          <a href={revoutAffiliateURl} target="_blank">
            <img src={revloutBanner} alt="img" className="w-100 rounded-3" />
          </a>
        </div>
      )}
      {layout === "Faq" && (
        <div className="action-bottom text-center mx-auto mt-5">
          <a href={revoutAffiliateURl} target="_blank">
            <img src={revloutSquireBanner} alt="img" className="w-100 rounded-3" />
          </a>
        </div>
      )}
      {layout === "blog" && (
        <div className="action-bottom text-center mx-auto mt-5">
          <a href={revoutAffiliateURl} target="_blank">
            <img src={revloutSquireBanner} alt="img" className="w-100 rounded-3" />
          </a>
        </div>
      )}
    </section>
  );
}

export default RevolutAffiliateBanner;