import React, { useState, useEffect, useContext } from 'react';
import T1 from '../../../../../dist/images/singleShop/services/arrowdn.png';
import T2 from '../../../../../dist/images/singleShop/services/arrowS.png';
import ArrowRight from '../../../../../dist/images/singleShop/services/arrowR.png';
import CartIcon from '../../../../../dist/images/singleShop/services/addCart.png';
import { toast } from 'react-toastify';
import { Animated } from "react-animated-css";
import { cartList } from "./ShopService";
import ToggleDescription from "./ToggleDescription";

const CartBtn = ({ onAdd, details, addBack }) => {
  //console.log('onAdd, details, addBack', onAdd, details, addBack)
  const cartItems = useContext(cartList);
  const [clickValue, setClickValue] = useState(true);
  // console.log("ref.current", ref.current)
  const ServiceAdd = () => {
    onAdd(details);
    toast.success("Added to cart!");
  }
  useEffect(() => {
    if (addBack) {
      // console.log("details.id === addBack", typeof details.id, typeof addBack)
      if (details.id === addBack) {
        setClickValue(true);
      }
    }
    if (cartItems.length > 0) {
      for (const item of cartItems) {
        if (item.id === details.id) {
          setClickValue(false);
        }
      }
    }
    if (cartItems.length === 0) {
      setClickValue(true);
    }
  }, [addBack, cartItems, details.id])
  return (
    <>
      <span className={clickValue ? "d-inline-block" : "d-none"}>
        <span className="CartIcon" onClick={() => { ServiceAdd() }} >
          <img src={CartIcon} alt="icon" />
        </span>
      </span>
    </>
  )
}

export default function Product({ content, onAdd, addBack, currency }) {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  // if (content) {
  //   console.log(content)
  // }

  return (
    <div id="shopServiceItem">
      {content?.services?.length > 0 &&
        <div
          onClick={() => {
            setVisible(!visible);
            setTimeout(() => {
              setShow(!show);
            }, 500)
          }}
          className="category"
        >
          {show ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
          <h5>{content?.name}</h5>
        </div>
      }
      {show &&
        <Animated animationIn="fadeIn" isVisible={visible} animationInDelay={200} className='service_content'>
          {(content?.services?.length > 0 &&
            content?.services?.map((services) => (
              services?.service_pricings?.map((details) => (
                <div className="row mx-0 service_content_row" key={details.id}>
                  <div className="col-4 SubCategory ps-4">
                    <div className="name">
                      <img src={ArrowRight} alt="icon" /><span>{details?.service_name}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-2 SubCategory">
                    <div className="price sp-price">
                      <span>{`${currency}${details.price}`}</span>
                    </div>
                  </div> */}
                  <div className="col-2 SubCategory">
                    {
                      details.special_price !== 0 ?
                        <div className="price sp-price">
                          <span>{`${currency}${details.price}`}</span>
                        </div> :
                        <div className="price">
                          <span>-</span>
                        </div>
                    }
                  </div>
                  <div className="col-2 SubCategory">
                    <div className="price">
                      <span>{`${currency}${details.special_price !== 0 ?
                        details.special_price : details.price}`}</span>
                    </div>
                  </div>
                  <div className="col-2 SubCategory">
                    <div className="time">
                      <span>{details.duration} min</span>
                    </div>
                  </div>
                  <div className="col-1 SubCategory">
                    <CartBtn onAdd={onAdd} details={details} addBack={addBack} />
                  </div>
                  {services?.description && <ToggleDescription desc={services?.description} />}
                </div>
              ))
            ))
          )}
        </Animated>
      }
    </div>
  );
}
