import React from 'react';
import ReactDOM from 'react-dom';
import App from './frontend/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.forEach(({ message }) => {
      console.log(`Graphql error ${message}`);
      if (message === "Unauthenticated.") {
        localStorage.clear();
        client.cache.reset();
        window.location.replace("/guest-login");
      }
    });
    if (networkError) {
      console.log("Network error", networkError);
    }
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: (window.location.host.includes("localhost:3000") || window.location.host.includes("guest.chuzeday.teknotunes.com")) ? "https://backend.cwprojects.xyz/graphql" : "https://backend.chuzeday.com/graphql" })
]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('guest_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

//make a client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
  fetchOptions: {
    mode: 'no-cors',
  },
  shouldBatch: true
});



ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);
