import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 3px 10px;
  }
  @media (max-width: 991px){
    flex-flow: column nowrap;
    background-color: #5c91b6;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(-0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    padding-left: 20px;
    li {
      color: #fff;
    }
  }
`;

const MobileCategory = ({ open,setOpen }) => {
  const guest_token = localStorage.getItem("guest_token");
  return (
    <Ul open={open}>
      <div className="back-home-main">
        {/* <Link to="/home" className="d-block mt-5 ms-5 mb-3">Home</Link> */}
        <Link to="/system/guest-query/guest_support" className="d-block ms-5 mb-3" onClick={() => setOpen(!open)}>Contact Us</Link>
        <Link to="/system/faq" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>FAQ</Link>
        <Link to="/system/about" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>About</Link>
        <Link to="/system/blog" className="d-block ms-5 mb-3" onClick={()=> setOpen(!open)}>Blog</Link>
        <Link to="/for-business" className="d-block ms-5 mb-3">For Business</Link>
        <Link to="/for-guest" className="d-block ms-5 mb-3">For Guest</Link>
        <Dropdown className="dropdown-wrap dropdown-wrap2 ms-5">
          <Dropdown.Toggle id="dropdown-basic" style={{ color: "#ebc11a;" }} className='bg-transfarent fs-5'>
            Free Trial
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="login-dropdown-body">
              <li><a href="https://business.chuzeday.com/auth/registration" className='text-start' target="_blank" rel="noreferrer">Business</a></li>
              <li><Link to="/signup" className="dropdown-item border-0 text-start">Guests</Link></li>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {/* {
          !guest_token &&
          <Link to="/signup" className="d-block ms-5">Sign Up For Free</Link>
        } */}
      </div>
    </Ul>
  )
}

export default MobileCategory;